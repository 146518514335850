import React, {useEffect, useState} from "react";
import Swiper from "../../../../assets/js/swiper.min";
import webUtils from "../../../../common/utils/web.utils";
import {
	CardFileVO,
	EduAnswerVO,
	EduContentVO,
	EduMainVO,
	QstCardVO
} from "../../../../core/education/education.vo";
import EducationService from "../../../../core/education/education.service";
import educationService from "../../../../core/education/education.service";
import {HWMap} from "../../../../core/common/common.vo";
import DateUtils from "../../../../common/utils/date.utils";
import {EduContentIndex, EduTitleDom} from "../EduMainDetail";
import StringUtils from "../../../../common/utils/string.utils";
import ToastUtils from "../../../../common/utils/toast.utils";
import PointService from "../../../../core/point/point.service";
import WebUtils from "../../../../common/utils/web.utils";
import FileUtils from "../../../../common/utils/file.utils";
import {BoardFileVO} from "../../../../core/board/board.vo";
import PdfViewer from "../../common/PdfViewer";
import ModalCardSlide from "../../common/ModalCardSlide";


const QstCard:React.FC<{paramMap:HWMap}> = ({paramMap}) => {
	
	const [requestStep, setRequestStep] = useState()
	const [eduMainVO, setEduMainVO] = useState<EduMainVO|null>(null)
	const [contentList, setContentList] = useState<EduContentVO[]|null>(null)
	const [eduContentVO, setContentVO] = useState<EduContentVO|null>(null)
	const [qstCardVO, setQstCardVO] = useState<QstCardVO|null>(null)
	const [cardFileList, setCardFileList] = useState<CardFileVO[]|null>(null)
	const [eduAnswerVO, setEduAnswerVO] = useState<EduAnswerVO|null>(null)

	const [ebook, setEbook] = React.useState<CardFileVO>();
	const [readSlideIndex, setReadSlideIndex] = useState<number | null>(null)
	const [readSlides, setReadSlides] = useState<CardFileVO[]>([])

	const [isLoading, setIsLoading] = useState(false);

	/**
	 * 받아온 데이터 정의
	 */
	useEffect(() => {
		setReadSlides([])
		WebUtils.log(paramMap)
		if (paramMap.eduMainVO) {
			setRequestStep(paramMap.requestStep)
			setEduMainVO(paramMap.eduMainVO)
			setContentList(paramMap.contentList)
			setContentVO(paramMap.eduContentVO)
			setQstCardVO(paramMap.eduContentVO.qstCardVO)
			setEduAnswerVO( paramMap.answerList[paramMap.requestStep] )
		}
	}, [paramMap]);
	
	
	
	useEffect(() => {

		(async () => {
			if ( eduContentVO?.ecIdx ) {
				const resultList = await EducationService.getCardFiles(eduContentVO.ecIdx);
				const sortedFileList = resultList.sort((a: BoardFileVO, b: BoardFileVO) => a.ordering - b.ordering);
				await setCardFileList(sortedFileList)
			}
		})()
		
	}, [eduContentVO])
	
	
	
	
	/**
	 * 카드 파일 렌더링 후
	 * 이미지나 PDF 실행
	 */
	useEffect(() => {
		WebUtils.log(cardFileList)

		if ( qstCardVO?.cardType === 'image' ) {
			
			if ( cardFileList && cardFileList.length > 0 ) {
				initSwiper();
			}
			
			//initSwiper()
		}
		if ( qstCardVO?.cardType === 'pdf' ) {
			
			if ( cardFileList && cardFileList.length > 0 ) {
				if (isLoading) return
				setIsLoading(true)

				cardFileList.forEach((file:CardFileVO) => {
					if ( file.fileext.toLowerCase() === "pdf" ) {
						setEbook(file)
					}
				})
				
				try {
					
					/**
					 * 학습가능상태 확인
					 */
					if ( paramMap.isPossible ) {
						
						/**
						 * PDF 일 경우 5초 뒤에 완료처리
						 */
						if ( !eduAnswerVO?.isComplete ) {
							setTimeout(async () => {
								EducationService.setAnsRecord(eduContentVO!.ecIdx, `END`, "card")
								const statusResult = await educationService.setAnswerStatus({
									eaIdx : eduAnswerVO!.eaIdx,
									isResult : false,
									isComplete : true,
								})
								if ( statusResult.status === "SUCCESS" ) {
									ToastUtils.show(`${eduContentVO?.qstCardVO.cardName} 완료되었습니다.`)
									
									// 포인트적립 : 학습 컨텐츠 완료
									await PointService.savePoint("COURSE_VIEW", eduContentVO!.ecIdx)
									
									paramMap._retrievePage(statusResult.allComplete)
								}
							}, 5000)
						}
					}
					
				} catch (e) {
					webUtils.log(e)
				} finally {
					setIsLoading(false)
				}
			}
			
		}
		
		WebUtils.goTop()
		
	}, [cardFileList])
	
	
	
	
	
	const initSwiper = () => {
		new Swiper(".detail .media-content .slide-wrap", {
			navigation: {
				nextEl: ".btn-next",
				prevEl: ".btn-prev",
			},
			pagination: {
				el: ".slide-page",
				type: "fraction",
			},
			loop: true,
			on: {
				slideChange: function() {
					const self:HWMap = this
					const activeIndex = self.realIndex + 1;
					const totalSlides = self.slides.length - 2;
					console.log(`현재 이미지: ${activeIndex}/${totalSlides}`);
				},
				transitionEnd: function () {
					const self:HWMap = this
					handleSlide(self.realIndex)
				}
			},
			observer: true, // 슬라이드가 변경될 때 DOM 변화를 감지하여 해결합니다.
			observeParents: true
		});
	}
	
	
	/**
	 * readSlides 저장때마다 슬라이드 이미지 수와 동일한지 확인
	 * 동일하면 해당 교육컨텐츠 완려처리
	 */
	useEffect(() => {
		
		/**
		 * 학습가능상태 확인
		 */
		if ( paramMap.isPossible ) {
			
			if ( readSlides && cardFileList && readSlides.length >= cardFileList.length ) {
				const isReadAll = cardFileList.every(card => readSlides.some(slide => slide.fidx === card.fidx))
				if ( isReadAll && !eduAnswerVO?.isComplete ) {
					//paramMap._retrievePage();
					(async () => {
						EducationService.setAnsRecord(eduContentVO!.ecIdx, `END`, "card")
						const result = await educationService.setAnswerStatus({
							eaIdx : eduAnswerVO!.eaIdx,
							isResult : false,
							isComplete : true,
						})
						if ( result.status === "SUCCESS" ) {
							ToastUtils.show(`${eduContentVO?.qstCardVO.cardName} 완료되었습니다.`)
							
							// 포인트적립 : 학습 컨텐츠 완료
							await PointService.savePoint("COURSE_VIEW", eduContentVO!.ecIdx)
							
							paramMap._retrievePage(result.allComplete)
						}
					})()
				}
			}
		}
	
	}, [readSlides])
	
	
	/**
	 * 본페이지 이미지 클릭시 슬라이드 인덱스 지정
	 * 인덱스 null > 번호 지정 순간 모달 슬라이드 출력
	 * @param _index
	 */
	const handleSlideClick = ( _index:number ) => {
		setReadSlideIndex(_index)
	}
	
	
	
	
	
	/**
	 * 카드슬라이드 콜백함수
	 * 본페이지 + 모달 통합 동작
	 * @param _index
	 */
	const handleSlide = (_index:number) => {
		
		let currentIndex = _index; // 현재 슬라이드의 인덱스
		console.log('Current slide index:', currentIndex);
		
		if ( cardFileList ) {
			const currentSlide = cardFileList[currentIndex];
			setReadSlides(prevState => {
				// 중복을 방지하기 위해 현재 슬라이드가 배열에 포함되어 있는지 확인
				if (!prevState.some(slide => slide.fidx === currentSlide.fidx)) {
					return [...prevState, currentSlide];
				}
				return prevState;
			});
		}
	}
	
	
	
	
	return (
		<section className="detail-section">
			<div className="inner">
				
				<EduTitleDom paramMap={{
					mainTitle: eduMainVO?.eduTitle,
					contentTitle: qstCardVO?.cardName,
					startDate : DateUtils.displayEduDate(eduMainVO?.scheduleMode, eduMainVO?.startDate),
					endDate : DateUtils.displayEduDate(eduMainVO?.scheduleMode, eduMainVO?.endDate),
					introduce : eduMainVO?.introduce
				}} />
				
				<div className="detail-content">
					<div className="detail-top" onContextMenu={(e) => e.preventDefault()}>
						<div className="media-content">
							{ qstCardVO?.cardType === `image` &&
								<>
									<div id={`IMAGEObject`} className="slide-wrap">
										<ul className="slide-list swiper-wrapper">
											{
												cardFileList?.map((value, index) => (
													<li className="slide-item swiper-slide" key={index}>
														<a href="javascript:;" onClick={() => handleSlideClick(index)}>
															<img src={FileUtils.getCardImageSrc(value)} alt={value.filename} className="img-cardnews" style={{
																objectFit: "contain",
																width: "100%"
															}}/>
														</a>
													</li>
												))
											}
										</ul>
										<div className="slide-page"></div>
										<div className="btn-prev"></div>
										<div className="btn-next"></div>
									</div>
									
									{(cardFileList && readSlideIndex !== null) && <ModalCardSlide cardList={cardFileList}
									                                                              selectedSlideIndex={readSlideIndex}
									                                                              closeModal={() => setReadSlideIndex(null)}
									                                                              handleSlide={handleSlide} ></ModalCardSlide>}
								</>
							}

							{ qstCardVO?.cardType === `pdf` && ebook &&
								<PdfViewer fileUrl={FileUtils.showEduCard(ebook) || ""} iframeHeight={500}/>
							}
							
						</div>
					</div>
					<div className="detail-bottom">
						<div className="tabs">
							<ul className="tab-menu">
								<li className="tab-item explain active">
									<a href="#" onClick={e=> e.preventDefault()}>강의 설명</a>
								</li>
							</ul>
							<div className="tab-panel tab-explain">
								{/*<div className="attach">
									<b className="tit">첨부파일</b>
									<span className="path">chumbufile40textrangenoproblem.pptx</span>
								</div>*/}
								<div className="text-area" dangerouslySetInnerHTML={{__html:StringUtils.nl2br(qstCardVO?.cardDesc) || ""}}></div>
							</div>
						</div>
					</div>
					
					<EduContentIndex paramMap={paramMap} />
					
				</div>
			</div>
		</section>
	)
}
export default QstCard