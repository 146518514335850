import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';

interface SummernoteIframeProps {
    mode: string;
    htmlCode?: string | null;
}

export interface SummernoteIframeHandle {
    getHtmlContent: () => string | null;
    getIframe: () => HTMLIFrameElement | null;
}

const SummernoteIframe = forwardRef<SummernoteIframeHandle, SummernoteIframeProps>(({ mode, htmlCode }, ref) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    let observer: MutationObserver;
    let imgObservers: HTMLImageElement[] = [];

    useImperativeHandle(ref, () => {
        return {
            getHtmlContent,
            getIframe
        }
    });

    const getHtmlContent = () => {
        const iframe = iframeRef.current;

        if (iframe === null) {
            return null;
        }

        const iframeWindow = iframe.contentWindow;

        if (iframeWindow === null) {
            return null;
        }

        const iframeDocument = iframe.contentDocument || iframeWindow.document;
        const summernoteElement = iframeDocument.getElementById("summernote");

        if (summernoteElement === null) {
            return null;
        }

        return (iframe.contentWindow as any).$("#summernote").summernote("code");
    }

    const getIframe = () => {
        return iframeRef.current;
    }

    /**
     * 옵저버 설정
     */
    const setupObserver = () => {
        observer = new MutationObserver(adjustIframeHeight);

        const destructor = () => {
            observer.disconnect();
        }

        const iframe = iframeRef.current;

        if (iframe === null) {
            return destructor;
        }

        const iframeWindow = iframe.contentWindow;

        if (iframeWindow === null) {
            return destructor;
        }

        const iframeDocument = iframe.contentDocument || iframeWindow.document;

        observer.observe(iframeDocument.documentElement, {
            childList: true,
            subtree: true,
            attributes: true,
        });

        return destructor;
    };

    /**
     * 높이 조절
     */
    const adjustIframeHeight = () => {
        const iframe = iframeRef.current;

        if (iframe === null) {
            return;
        }

        const iframeWindow = iframe.contentWindow;

        if (iframeWindow === null) {
            return;
        }

        iframeWindow.requestAnimationFrame(() => {
            const iframeDocument = iframe.contentDocument || iframeWindow.document;

            const contentHeight = Array.from(iframeDocument.body.children)
                .reduce((height, element) => {
                    const computedStyle = iframeWindow.getComputedStyle(element);
                    const marginTop = parseInt(computedStyle.marginTop) || 0;
                    const marginBottom = parseInt(computedStyle.marginBottom) || 0;
                    const borderTop = parseInt(computedStyle.borderTop) || 0;
                    const borderBottom = parseInt(computedStyle.borderBottom) || 0;

                    return height
                        + element.clientHeight
                        + marginTop
                        + marginBottom
                        + borderTop
                        + borderBottom;
                }, 0);

            iframe.style.height = `${contentHeight}px`;
        });
    };

    /**
     * HTML 코드 삽입
     */
    const handleDom = () => {
        const iframe = iframeRef.current;

        if (iframe === null) {
            return;
        }

        const iframeWindow = iframe.contentWindow;

        if (iframeWindow === null) {
            return;
        }

        const iframeDocument = iframe.contentDocument || iframeWindow.document;

        let summernoteElement = null;

        if (mode === "edit") {
            summernoteElement = iframeDocument.querySelector(".note-editable");
        } else if (mode === "view") {
            summernoteElement = iframeDocument.getElementById("summernote");
        }

        if (summernoteElement === null) {
            return;
        }

        summernoteElement.innerHTML = htmlCode || "";

        // 옵저버 설정
        const imgElements = summernoteElement.getElementsByTagName("img");

        for (const imageElement of Array.from(imgElements)) {
            imgObservers.push(imageElement);

            // 캐시된 이미지 처리
            if (imageElement.complete) {
                adjustIframeHeight();
            } else {
                imageElement.addEventListener("load", adjustIframeHeight);
            }
        }

        adjustIframeHeight();
    };

    const handleIframeLoad = () => {
        const iframe = iframeRef.current;

        const destructor = () => {
            if (iframe !== null) {
                iframe.removeEventListener("load", handler);
            }
        }

        if (iframe === null) {
            return destructor;
        }

        const handler = () => {
            if (iframe === null) {
                return;
            }

            const iframeWindow = iframe.contentWindow;

            if (iframeWindow === null) {
                return;
            }

            const iframeDocument = iframe.contentDocument || iframeWindow.document;

            iframeDocument.body.style.overflow = "hidden"
            iframeDocument.body.style.margin = "0"
        };

        iframe.addEventListener("load", handler);

        return destructor;
    };

    // 브라우저 또는 폴더블 디바이스 고려
    const handleWindowResize = () => {
        window.addEventListener("resize", adjustIframeHeight);

        // destructor
        return () => {
            window.removeEventListener("resize", adjustIframeHeight);
        }
    };

    // init
    useEffect(() => {
        const setObserverDestructor = setupObserver();
        const handleIframeLoadDestructor = handleIframeLoad();
        const handleWindowResizeDestructor = handleWindowResize();

        return () => {
            for (const imageElement of imgObservers) {
                imageElement.removeEventListener("load", adjustIframeHeight);
            }

            setObserverDestructor();
            handleIframeLoadDestructor();
            handleWindowResizeDestructor();
        };
    }, []);

    /**
     * 모드(mode) 변경 시
     */
    useEffect(() => {
        const baseUrl = "/elandedu/assets/summernote/";
        const html = mode === "edit" ? "summernote_form.html" : "summernote_view.html";
        const url = `${baseUrl}${html}`;

        if (iframeRef.current) {
            iframeRef.current.src = url;
        }
    }, [mode]);

    /**
     * htmlCode 변경 시
     */
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (iframeRef.current && iframeRef.current.contentWindow) {
                const iframeWindow = iframeRef.current.contentWindow;
                const iframeDocument = iframeWindow.document;
                let summernoteElement = null;
                if (mode === "edit") {
                    summernoteElement = iframeDocument.querySelector('.note-editable');
                } else if (mode === "view") {
                    summernoteElement = iframeDocument.getElementById('summernote');
                }

                // 'summernote' 요소가 존재하는지 확인 후 handleDom 호출
                if (summernoteElement) {
                    clearInterval(intervalId); // 확인 후 반복 종료
                    handleDom();
                }
            }
        }, 200);

        // Clean up
        return () => {
            clearInterval(intervalId);
        };
    }, [htmlCode]);

    return (
        <div style={{width: '100%', height: mode === 'edit' ? '640px' : 'auto', overflow: 'hidden'}}>
            <iframe
                ref={iframeRef}
                style={{width: '100%', height: '100%', border: 'none', overflow: 'hidden'}}
                title="Summernote Editor"
            />
        </div>
    );
});

SummernoteIframe.displayName = "SummernoteIframe";

export default SummernoteIframe;
