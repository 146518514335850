import React, {useEffect} from "react";
import SwalUtils from "../../../common/utils/swal.utils";
import {Link, useLocation, useNavigate} from "react-router-dom";

export const M_CheckService: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const alertMessage = location.state?.alertMessage;


    useEffect(() => {
        if (alertMessage) {
            SwalUtils.text({
                title : `안내`,
                message : alertMessage,
                isCancel : false,
                confirmOptions : {
                    text : "확인",
                    eventHandler : () => {navigate(-2)}
                }
            })
        }
    }, [alertMessage]);


    return (
        <main className="content check">
            <section className="check-section">
                <div className="inner">
                    <div className="common-title">
                        <div className="inner">
                            <h2 className="tit">시스템 점검중입니다.</h2>
                            <p className="desc">이용에 불편을 드려 죄송합니다.<br /> 원활한 시스템 개선을 위한 점검중입니다.</p>
                        </div>
                    </div>
                    <div className="button-area">
                        <button type="button" className="btn">홈으로 돌아가기</button>
                    </div>
                </div>
            </section>
        </main>
    );
};