import React, {useEffect, useState} from 'react';
import './App.css';
import {BrowserRouter, Route, Routes, useLocation} from 'react-router-dom';
import M_Main from "./components/mobile/home/M_Main";
import M_Header from "./components/mobile/layout/M_Header";
import {M_Footer, M_FooterBack, M_FooterClose} from "./components/mobile/layout/M_Footer";
import M_BoardList from "./components/mobile/board/M_BoardList";
import M_ScrollToTop from "./components/mobile/common/M_ScrollToTop";
import M_AuthGuard from "./components/mobile/session/M_AuthGuard";
import M_EduMainDetail from "./components/mobile/education/M_EduMainDetail";
import M_EduMainList from "./components/mobile/education/M_EduMainList";
import M_Dashboard from "./components/mobile/education/dashboard/M_Dashboard";
import M_Storagebox from "./components/mobile/education/M_Storagebox";
import M_ProFind from "./components/mobile/pro/find/M_ProFind";
import M_login from "./components/mobile/session/M_login";
import M_Search from "./components/mobile/search/M_Search";
import M_Menu from "./components/mobile/common/M_Menu";
import M_BoardDetail from "./components/mobile/board/M_BoardDetail";
import M_ProPersonal from "./components/mobile/pro/personal/M_ProPersonal";
import M_JobSkillMain from "./components/mobile/mypage/jobskill/intro/M_JobSkillMain";
import M_JobSkillInput from "./components/mobile/mypage/jobskill/input/M_JobSkillInput";
import M_SearchResult from "./components/mobile/search/M_SearchResult";
import M_ProHelp from "./components/mobile/pro/help/M_ProHelp";
import M_ProHelpList from "./components/mobile/pro/help/list/M_ProHelpList";
import M_UseStoryList from "./components/mobile/board/useStory/M_UseStoryList";
import M_ProHelpForm from "./components/mobile/pro/help/form/M_ProHelpForm";
import M_Mypage from "./components/mobile/mypage/M_Mypage";
import M_UseStoryForm from "./components/mobile/board/useStory/M_UseStoryForm";
import M_AccountManagement from "./components/mobile/mypage/management/M_AccountManagement";
import M_ProHelpDetail from "./components/mobile/pro/help/detail/M_ProHelpDetail";
import M_ProEduTalk from "./components/mobile/pro/edutalk/list/M_ProEduTalk";
import M_ProEduTalkDetail from "./components/mobile/pro/edutalk/detail/M_ProEduTalkDetail";
import M_MyPoint from "./components/mobile/mypage/point/M_MyPoint";
import M_PrivacyBoard from "./components/mobile/mypage/privacy/M_PrivacyBoard";
import M_FrequentlyQuestion from "./components/mobile/mypage/question/M_FrequentlyQuestion";
import M_MypageBoard from "./components/mobile/mypage/board/M_MypageBoard";
import M_MypageBoardForm from "./components/mobile/mypage/board/M_MypageBoardForm";
import M_MypageBoardDetail from "./components/mobile/mypage/board/M_MypageBoardDetail";
import M_Alarm from "./components/mobile/mypage/alarm/M_Alarm";
import {M_Error} from "./components/mobile/etc/M_Error";
import AuthGuard from "./components/pc/session/AuthGuard";
import CommonMobileJS from "./common/common_m";
import {M_CheckService} from "./components/mobile/etc/M_CheckService";

function AppMobile() {

    const qs = new URLSearchParams(location.search)
    const [noLayout, setNoLayout] = useState(Boolean(qs.get("noLayout")));
    const [layoutMode, setLayoutMode] = useState(String(qs.get("layoutMode")));
    const [forReload, setForReload] = useState<number>(0)
    useEffect(() => {

    }, [])

    const reload = ()=> {
        setForReload(prev=>prev + 1)
    }

    const FooterWithKey = () => {
        const location = useLocation();
    
        if (layoutMode.includes("close")) {
            return <M_FooterClose key={location.pathname} />;
        }
    
        if (layoutMode.includes("back")) {
            return <M_FooterBack key={location.pathname} />;
        }
        
        return <M_Footer key={location.pathname} />;
    };
    

    return (

        /**
         * 로그인 검증 컴포넌트
         * 사용법:
         * <M_M_AuthGuard>
         *   <ProtectedComponent />
         * </M_M_AuthGuard>
         */

        <BrowserRouter basename={process.env.PUBLIC_URL}>
            {noLayout || <M_Header/>}
            <M_ScrollToTop/>
            <Routes>

                {/* 메인 화면*/}
                <Route path="/" element={<M_Main/>}/>

                {/*로그인*/}
                <Route path="/login/" element={<M_login {...{reload:reload}}/>}/>

                {/*검색*/}
                <Route path="/search">
                    <Route path={""} element={<M_AuthGuard><M_Search/></M_AuthGuard>}/>
                    <Route path={":searchText"} element={<M_AuthGuard><M_SearchResult /></M_AuthGuard>}/>
                </Route>

                {/*메뉴*/}
                <Route path="/menu" element={<M_Menu />}/>

                {/* 지식콘텐츠 (게시판) */}
                <Route path={"/board"}>
                    <Route path={"list/:boardKey"} element={<M_AuthGuard><M_BoardList /></M_AuthGuard>} />
                    <Route path={":boardKey/:bidx/:boardTitle"} element={<M_AuthGuard><M_BoardDetail /></M_AuthGuard>} />
                    <Route path={"story/:boardKey"} element={<M_AuthGuard><M_UseStoryList /></M_AuthGuard>} />
                    <Route path={"story/:boardKey/:bidx/:boardTitle"} element={<M_AuthGuard><M_UseStoryList /></M_AuthGuard>} />
                    <Route path={"story/:boardKey/:bidx/:boardTitle/form"} element={<M_AuthGuard><M_UseStoryForm /></M_AuthGuard>} />
                </Route>

                {/* 코스학습 */}
                <Route path={"/education"}>
                    <Route path={":openStatus"} element={<M_AuthGuard><M_EduMainList/></M_AuthGuard>}/>
                    <Route path={"dashboard"} element={<M_AuthGuard><M_Dashboard/></M_AuthGuard>}/>
                    <Route path={":emIdxText/:stepText/:eduTitle"} element={<M_AuthGuard><M_EduMainDetail/></M_AuthGuard>}/>
                    <Route path={"storagebox"} element={<M_AuthGuard><M_Storagebox type={'recent'}/></M_AuthGuard>}/>
                    <Route path={"storagebox_wish"} element={<AuthGuard><M_Storagebox type={"wish"}/></AuthGuard>}/>
                </Route>

                {/* 사내고수 */}
                <Route path={"/pro"}>
                    <Route path={""} element={<M_AuthGuard><M_ProFind/></M_AuthGuard>}/>
                    <Route path={":proidx"} element={<M_AuthGuard><M_ProPersonal/></M_AuthGuard>}/>
                    <Route path={"help"}>
                        <Route path={""} element={<M_AuthGuard><M_ProHelp/></M_AuthGuard>}>
                            <Route path={""} element={<M_AuthGuard><M_ProHelpList/></M_AuthGuard>}/>
                            <Route path={"form"} element={<M_AuthGuard><M_ProHelpForm/></M_AuthGuard>}/>
                            <Route path={"form/:bidx"} element={<M_AuthGuard><M_ProHelpForm/></M_AuthGuard>}/>
                        </Route>
                        <Route path={":bidx"} element={<M_AuthGuard><M_ProHelpDetail/></M_AuthGuard>}/>
                    </Route>
                    <Route path={"talk"}>
                        <Route path={""} element={<M_AuthGuard><M_ProEduTalk/></M_AuthGuard>}/>
                        <Route path={":bidx"} element={<M_AuthGuard><M_ProEduTalkDetail/></M_AuthGuard>}/>
                    </Route>
                </Route>

                {/*마이페이지*/}
                <Route path={"/mypage"}>
                    <Route path={""} element={<M_AuthGuard><M_Mypage /> </M_AuthGuard>} />
                    <Route path={"alarm"} element={<M_AuthGuard><M_Alarm/></M_AuthGuard>}/>
                    <Route path={"management"} element={<M_AuthGuard><M_AccountManagement /> </M_AuthGuard>} />
                    <Route path={"point"} element={<M_AuthGuard><M_MyPoint /></M_AuthGuard>} />
                    <Route path={"jobskill"}>
                        <Route path={""} element={<M_AuthGuard><M_JobSkillMain/></M_AuthGuard>}/>
                        <Route path={"input"} element={<M_AuthGuard><M_JobSkillInput/></M_AuthGuard>}/>
                    </Route>
                    <Route path={"board"}>
                        <Route path={":boardKey"} element={<M_AuthGuard><M_MypageBoard /></M_AuthGuard>} />
                        <Route path={":boardKey/form"} element={<M_AuthGuard><M_MypageBoardForm /></M_AuthGuard>} />
                        <Route path={":boardKey/:bidx"} element={<M_AuthGuard><M_MypageBoardDetail /></M_AuthGuard>} />
                        <Route path={":boardKey/:bidx/form"} element={<M_AuthGuard><M_MypageBoardForm /></M_AuthGuard>} />
                    </Route>
                    <Route path={"frequently"} element={<M_FrequentlyQuestion />} />
                </Route>

                <Route path={"/privacy"} element={<M_PrivacyBoard />}/>
    
                
                {/* 시스템 점검중 페이지 */}
                <Route path={"/checkService"} element={<M_CheckService/>}/>
                

                {/* 그 외 모든 경로는 이곳으로... */}
                <Route path="*" element={<M_Error />} />
            </Routes>
            { noLayout || <FooterWithKey /> }
        </BrowserRouter>
    );
}


export default AppMobile;
