import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import {store} from './common/redux/store';
import AppPc from './AppPc';
import AppMobile from './AppMobile';
import {useIsMobile} from "./common/hook/useMediaQuery";
import {QueryClient, QueryClientProvider} from "react-query";


const RootComponent: React.FC = () => {
    const isMobile = useIsMobile();
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        
        /*console.log(`%c
      HHH     HHH    WWW         WWW    SSSSSSSSS
      HHH     HHH    WWW         WWW  SSS       SSS
      HHH     HHH    WWW         WWW  SSS
      HHHHHHHHHHH    WWW    W    WWW    SSSSSSSSS
      HHHHHHHHHHH     WWW  WWW  WWW             SSS
      HHH     HHH      WWW WWW WWW     SSS     SSSS
      HHH     HHH       WW     WW       SSSSSSSSS
  `, "font-family: monospace; font-size: 16px; color: #0055ff;");*/
        
        // media query 상태가 결정되면 ready 상태로 설정
        setIsReady(true);
    }, [isMobile]);



    if (!isReady) {
        // 초기 렌더링 시 아무 컴포넌트도 렌더링하지 않음 (api 호출 방지)
        return null;
    }

    return isMobile ? <QueryClientProvider client={new QueryClient()}><AppMobile /></QueryClientProvider> : <AppPc />;
};

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <Provider store={store}>
        <RootComponent />
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
