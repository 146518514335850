import React, {useEffect, useState} from 'react';
import './App.css';
import {BrowserRouter, Route, Routes, useLocation} from 'react-router-dom';
import Test from "./sample/Test";
import Main from "./components/pc/home/main";
import Header from "./components/pc/layout/header";
import Footer from "./components/pc/layout/footer";
import Login from "./components/pc/session/login";
import EduMainList from "./components/pc/education/EduMainList";
import Dashboard from "./components/pc/education/dashboard/Dashboard";
import BoardList from "./components/pc/board/BoardList";
import BoardDetail from "./components/pc/board/BoardDetail";
import ProFind from "./components/pc/pro/find/ProFind";
import EduMainDetail from "./components/pc/education/EduMainDetail";
import ProPersonal from "./components/pc/pro/personal/ProPersonal";
import ScrollToTop from "./components/pc/common/ScrollToTop";
import ProHelpList from "./components/pc/pro/help/list/ProHelpList";
import ProHelp from "./components/pc/pro/help/ProHelp";
import ProHelpForm from "./components/pc/pro/help/form/ProHelpForm";
import ProHelpDetail from "./components/pc/pro/help/detail/ProHelpDetail";
import ProEduTalkDetail from "./components/pc/pro/edutalk/detail/ProEduTalkDetail";
import BoardForm from "./components/pc/board/BoardForm";
import ProRequest from "./components/pc/pro/request/ProRequest";
import ProRequestSubmit from "./components/pc/pro/request/ProRequestSubmit";
import UseStoryList from "./components/pc/board/useStory/UseStoryList";
import UseStoryForm from "./components/pc/board/useStory/UseStoryForm";
import AlertTest from "./components/pc/test/AlertTest";
import ProEduTalk from "./components/pc/pro/edutalk/list/ProEduTalk";
import Storagebox from "./components/pc/education/Storagebox";
import SearchResult from "./components/pc/search/SearchResult";
import JobSkillInput from "./components/pc/mypage/jobskill/input/JobSkillInput";
import AccountManagement from "./components/pc/mypage/management/AccountManagement";
import PrivacyBoard from "./components/pc/mypage/privacy/PrivacyBoard";
import MyPoint from "./components/pc/mypage/point/MyPoint";
import FrequentlyQuestion from "./components/pc/mypage/question/FrequentlyQuestion";
import MypageBoard from "./components/pc/mypage/board/MypageBoard";
import MypageBoardDetail from "./components/pc/mypage/board/MypageBoardDetail";
import MypageBoardForm from "./components/pc/mypage/board/MypageBoardForm";
import AuthGuard from "./components/pc/session/AuthGuard";
import JobSkillMain from "./components/pc/mypage/jobskill/intro/JobSkillMain";
import {Error} from "./components/pc/etc/Error";
import AlarmList from "./components/pc/mypage/alarm/AlarmList";
import {CheckService} from "./components/pc/etc/CheckService";

function AppPc() {

    const qs = new URLSearchParams(location.search)
    const [noLayout, setNoLayout] = useState(Boolean(qs.get("noLayout")));
    const [forReload, setForReload] = useState<number>(0)
    useEffect(() => {

    }, [])

    const reload = ()=> {
        setForReload(prev=>prev + 1)
    }

    const HeaderWithKey = () => {
        const location = useLocation();
        return <Header key={location.pathname} />;
    };

    return (

        /**
         * 로그인 검증 컴포넌트
         * 사용법:
         * <M_AuthGuard>
         *   <ProtectedComponent />
         * </M_AuthGuard>
         */

        <BrowserRouter basename={process.env.PUBLIC_URL}>
            {noLayout || <HeaderWithKey/>}
            <ScrollToTop/>
            <Routes>
                <Route path="/" element={<Main/>}/>
                <Route path="/test/" element={<Test/>}/>

                {/* 전체검색 */}
                <Route path={"/search"}>
                    <Route path={":searchText"} element={<AuthGuard><SearchResult /></AuthGuard>}/>
                </Route>

                {/* 사내고수 */}
                <Route path={"/pro"}>
                    <Route path={""} element={<AuthGuard><ProFind/></AuthGuard>}/>
                    <Route path={":proidx"} element={<AuthGuard><ProPersonal/></AuthGuard>}/>
                    <Route path={"help"}>
                        <Route path={""} element={<AuthGuard><ProHelp/></AuthGuard>}>
                            <Route path={""} element={<AuthGuard><ProHelpList/></AuthGuard>}/>
                            <Route path={"form"} element={<AuthGuard><ProHelpForm/></AuthGuard>}/>
                            <Route path={"form/:bidx"} element={<AuthGuard><ProHelpForm/></AuthGuard>}/>
                        </Route>
                        <Route path={":bidx"} element={<AuthGuard><ProHelpDetail/></AuthGuard>}/>
                    </Route>
                    <Route path={"request"}>
                        <Route path={""} element={<AuthGuard><ProRequest/></AuthGuard>}/>
                        <Route path={"submit"} element={<AuthGuard><ProRequestSubmit/></AuthGuard>}/>
                    </Route>
                    <Route path={"talk"}>
                        <Route path={""} element={<AuthGuard><ProEduTalk/></AuthGuard>}/>
                        <Route path={":bidx"} element={<AuthGuard><ProEduTalkDetail/></AuthGuard>}/>
                    </Route>
                </Route>

                <Route path="/login/" element={<Login {...{reload:reload}}/>}/>

                <Route path={"/education"}>
                    <Route path={":openStatus"} element={<AuthGuard><EduMainList/></AuthGuard>}/>
                    <Route path={"dashboard"} element={<AuthGuard><Dashboard/></AuthGuard>}/>
                    <Route path={":emIdxText/:stepText/:eduTitle"} element={<AuthGuard><EduMainDetail/></AuthGuard>}/>
                    <Route path={"storagebox"} element={<AuthGuard><Storagebox type={"recent"}/></AuthGuard>}/>
                    <Route path={"storagebox_wish"} element={<AuthGuard><Storagebox type={"wish"}/></AuthGuard>}/>
                </Route>

                {/* 지식콘텐츠 */}
                <Route path={"/board"}>
                    <Route path={"list/:boardKey"} element={<AuthGuard><BoardList /></AuthGuard>} />
                    <Route path={":boardKey/:bidx/:boardTitle"} element={<AuthGuard><BoardDetail /></AuthGuard>} />
                    <Route path={":boardKey/:bidx/:boardTitle/form"} element={<AuthGuard><BoardForm /></AuthGuard>} />

                    <Route path={"story/:boardKey"} element={<UseStoryList />} />
                    <Route path={"story/:boardKey/:bidx/:boardTitle"} element={<UseStoryList />} />
                    <Route path={"story/:boardKey/:bidx/:boardTitle/form"} element={<AuthGuard><UseStoryForm /></AuthGuard>} />
                </Route>

                <Route path={"/mypage"}>
                    <Route path={""} element={<AuthGuard><AccountManagement/></AuthGuard>} />
                    <Route path={"alarm"} element={<AuthGuard><AlarmList/></AuthGuard>} />
                    <Route path={"management"} element={<AuthGuard><AccountManagement/></AuthGuard>} />
                    <Route path={"jobskill"}>
                        <Route path={""} element={<AuthGuard><JobSkillMain/></AuthGuard>}/>
                        <Route path={"input"} element={<AuthGuard><JobSkillInput/></AuthGuard>}/>
                    </Route>
                    <Route path={"point"} element={<AuthGuard><MyPoint /></AuthGuard>} />
                    <Route path={"board"}>
                        <Route path={":boardKey"} element={<AuthGuard><MypageBoard /></AuthGuard>} />
                        <Route path={":boardKey/form"} element={<AuthGuard><MypageBoardForm /></AuthGuard>} />
                        <Route path={":boardKey/:bidx"} element={<AuthGuard><MypageBoardDetail /></AuthGuard>} />
                        <Route path={":boardKey/:bidx/form"} element={<AuthGuard><MypageBoardForm /></AuthGuard>} />
                    </Route>
                    <Route path={"frequently"} element={<FrequentlyQuestion />} />
                </Route>
                <Route path={"/alert"}>
                    <Route path={""} element={<AlertTest />} />
                </Route>

                <Route path={"/privacy"} element={<PrivacyBoard/>}/>
                
                
                {/* 시스템 점검중 페이지 */}
                <Route path={"/checkService"} element={<CheckService/>}/>
                

                {/* 그 외 모든 경로는 이곳으로... */}
                <Route path="*" element={<Error />} />

            </Routes>
            { noLayout || <Footer /> }
        </BrowserRouter>
    );
}


export default AppPc;
