import React, {useEffect, useState} from "react";
import {EduAnswerVO, EduContentVO, EduMainVO, EduStudentVO} from "../../../core/education/education.vo";
import {useNavigate, useParams} from "react-router-dom";
import QstMov from "./question/QstMov";
import QstCard from "./question/QstCard";
import QstTst from "./question/QstTst";
import QstFile from "./question/QstFile";
import QstPoll from "./question/QstPoll";
import {HWMap} from "../../../core/common/common.vo";
import EducationService from "../../../core/education/education.service";
import WebUtils from "../../../common/utils/web.utils";
import QstTstResult from "./question/QstTstResult";
import AudienceReview from "../review/AudienceReview";
import {ReviewType} from "../review/ReviewType";
import {SessionVO} from "../../../core/common/session.vo";
import MemberUtils from "../../../common/utils/member.utils";
import {CompanyAccountVO} from "../../../core/company/company.vo";
import SwalUtils from "../../../common/utils/swal.utils";
import ToastUtils from "../../../common/utils/toast.utils";
import PointService from "../../../core/point/point.service";
import axiosCaller from "../../../common/utils/axios.caller";
import APIs from "../../../common/apis/APIs";
import FileUtils from "../../../common/utils/file.utils";
import CommonJS from "../../../common/common";
import dateUtils from "../../../common/utils/date.utils";
import ProEduTalkModal from "../common/ProEduTalkModal";


const EduMainDetail = () => {
	
	const navigate = useNavigate();
	const [sessionVO, setSessionVO] = useState<SessionVO | null>(MemberUtils.getSessionVO());
	
	let {emIdxText, stepText, eduTitle} = useParams();
	
	const [emIdx, setEmIdx] = useState<number>(0);
	const [eduMainVO, setEduMainVO] = useState<EduMainVO>();
	const [studentVO, setStudentVO] = useState<EduStudentVO>();
	const [eduAnswerVO, setEduAnswerVO] = useState<EduAnswerVO>();
	const [answerList, setAnswerList] = useState<EduAnswerVO[]>();
	const [teacherVO, setTeacherVO] = useState<CompanyAccountVO>();
	
	let [requestStep, setRequestStep] = useState<number>(0);
	const [paramMap, setParamMap] = useState<HWMap>({});
	
	const [isReadable, setIsReadable] = useState<boolean>(false)
	const [isPossible, setIsPossible] = useState<boolean>(false)
	
	const [created, setCreated] = useState<boolean>(false)
	const [mounted, setMounted] = useState<boolean>(false)
	
	
	/**
	 * 교육
	 */
	useEffect(() => {
		if (!created) {
			
			(async() => {
				const result:HWMap = await axiosCaller.get(`${APIs.EDU_MAIN}/checkEduMain`, {
					emIdx : emIdxText,
					userId : sessionVO?.coEmailId
				})
				console.log(result)
				if ( result.status !== "SUCCESS" ) {
					
					switch (result.msg) {
						case "NO_PARAM_USERID":
						case "NO_PARAM_EMIDX":
							ToastUtils.show("정상적인 접근이 아닙니다.")
							navigate(-1)
							break;
						case "NO_USER":
							ToastUtils.show("로그인 사용자만 접근 가능합니다.")
							navigate(`/login`)
							break;
						case "NO_TARGET":
							ToastUtils.show("교육대상이 아닙니다.")
							navigate(-1)
							break;
						case "NO_SCHEDULE":
							SwalUtils.text({
								title : "코스학습",
								message : `교육 시작일자를 확인해주세요.<br />현재는 미리 보실 수 없어요.`,
								confirmOptions: {
									text: "확인",
									eventHandler: () => navigate(-1)
								}
							})
							break;
					}
				} else {
					setIsReadable(result.data.isReadable)
					setIsPossible(result.data.isPossible)
					setCreated(true)
				}
			})();
			//setCreated(true)
		}
	}, [])
	
	
	
	/**
	 * 교육번호 조회
	 * 진행 스텝인덱스 정의
	 */
	useEffect(() => {
		
		if ( created ) {
			if (emIdxText && stepText) {
				setEmIdx(parseInt(emIdxText, 10))
				setRequestStep(parseInt(stepText, 10))
			} else {
				navigate(-1)
			}
			
			if ( !sessionVO || !sessionVO!.coEmailId ) {
				SwalUtils.text({
					title : "코스학습",
					message : "로그인 사용자만 접근 가능합니다.",
					confirmOptions : {
						text : "확인",
						eventHandler : () => navigate("/login/")
					},
				})
				//alert("로그인 사용자만 사용가능.")
				//navigate(-1)
			}
		}
		
	}, [created, emIdxText, stepText]);
	
	
	/**
	 * 교육정보, 컨텐츠 목록, 답변 목록 조회
	 * 진행할 인덱스 설정
	 */
	useEffect(() => {
		
		if (emIdx > 0) {
			retrievePage();
		}
		
	}, [emIdx])
	
	
	/**
	 * 진행할 스텝으로 컨텐츠 표출
	 */
	useEffect(() => {
		
		if (eduMainVO) {
			WebUtils.log(eduMainVO)
			eduMainVO.contentList[requestStep]?.teacherVO ? setTeacherVO( eduMainVO.contentList[requestStep].teacherVO ) : setTeacherVO(eduMainVO.teacherVO)
			
			setParamMap({
				...paramMap,
				eduMainVO: eduMainVO,
				contentList: eduMainVO?.contentList,
				studentVO: studentVO,
				answerList: answerList,
				eduContentVO: eduMainVO?.contentList[requestStep],
				requestStep: requestStep,
				isReadable: isReadable,
				isPossible: isPossible,
				_retrievePage: (_allComplete:boolean = false) => retrievePage(_allComplete),
				_goStep: (_step:number) => goStep(_step)
			})
			setMounted(true)
		}
		
	}, [eduMainVO])
	
	/*useEffect(() => {
		if (eduMainVO) {
			setEmIdx(eduMainVO.emIdx)
		}
	}, [eduMainVO]);*/
	
	
	/**
	 * 페이지 갱신
	 * @param _allComplete
	 */
	const retrievePage = async (_allComplete:boolean = false) => {
		
		if ( _allComplete ) {
			await completeContents(_allComplete)
		}
		
		const result = await EducationService.getEduMainOne(emIdx)
		if (result && result.status === "SUCCESS") {
			
			if ( result.studentResult.status !== `SUCCESS` ) {
				if ( result.studentResult.msg === `NO_TARGET` ) {
					SwalUtils.text({
						title : "코스학습",
						message : "교육 대상자가 아닙니다.",
						confirmOptions : {
							text : "확인",
							eventHandler : () => navigate(-1)
						},
					})
					// alert("교육 대상자가 아닙니다.");
					// history.back()
					return
				}
			} else {
				setEduMainVO(result.eduMain)
				setStudentVO(result.studentResult.studentVO)
				setAnswerList(result.studentResult.answerList)
				setEduAnswerVO(result.studentResult.answerList[requestStep])
			}
		}
	}
	
	
	/**
	 * 목표인덱스 전단계 다 완료가 됐는지 확인
	 * 강제진행 + 미진행 인덱스 존재시 미진행 인덱스반환
	 * 자유진행일 경우 목표인덱스 반환
	 * @param _step
	 */
	const getMissStep = async (_step:number) => {
		
		let arrMissIndex:number[] = [];
		
		if ( !eduMainVO?.isStep ) {
			return arrMissIndex;
		}
		
		let isMissIndex = false
		if ( answerList ) {
			for (let i = 0; i < answerList.length; i++) {
				if (i < _step && !answerList[i].isComplete) {
					arrMissIndex.push(i)
					isMissIndex = true;
				}
			}
		}
		
		return arrMissIndex;
		
	}
	
	
	
	
	const goStep = async (_step:number) => {
		
		//await retrievePage()
		
		/**
		 * 완료 안된 목록 조회
		 */
		let arrMissIndex = await getMissStep(_step)
		
		
		if ( arrMissIndex.length <= 0 ) {
			await navigate(`/education/${emIdxText}/${_step}/${eduTitle}`, {
				replace: true
			});
			await retrievePage()
		} else {
			
			await SwalUtils.text({
				title : "코스학습",
				message : "순차교육입니다. 전단계 교육을 끝내주세요.",
				confirmOptions: {
					text : "확인",
					eventHandler : async () => {
						await navigate(`/education/${emIdxText}/${arrMissIndex[0]}/${eduTitle}`, {
							replace: true
						})
						await retrievePage()
						return;
					}
				}
			})
		}
		
	}
	
	
	/**
	 * 해당 교육을 끝났는지 확인 후
	 * 더이상 미완료 교육이 없을 경우 완료처리
	 */
	const completeContents = async (_allComplete:boolean) => {
		if ( _allComplete && !studentVO?.isComplete ) {
			
			// 포인트적립 : 코스학습 완료
			await PointService.savePoint("COURSE_COMPLETE", eduMainVO!.emIdx)
			
			SwalUtils.text({
				title : "",
				message : `<strong style="color: #1847ED">[${eduMainVO?.eduTitle}]</strong><br /><strong>모든 과정을 완료하셨습니다. 축하드립니다.</strong>`,
				isIcon: true,
				isCancel : false,
				confirmOptions : {
					text : "확인",
				},
			})
		}
	}
	
	
	
	
	
	return (
		<main className="content course detail" key={requestStep}>
				{
					eduMainVO && eduMainVO.contentList && (
						<>
							{/* 영상 컨텐츠 */}
							{eduMainVO.contentList[requestStep]?.contentType === `mov` && <QstMov paramMap={paramMap}/>}

							{/* 카드뉴스 컨텐츠 */}
							{eduMainVO.contentList[requestStep]?.contentType === `card` && <QstCard paramMap={paramMap}/>}

							{/* 시험 컨텐츠 */}
							{eduMainVO.contentList[requestStep]?.contentType === `tst` &&
								<>
									{/*{answerList && "::::::::::::::::::::::::::::::::::::::::::::::" + answerList[requestStep]!.isResult}*/}
									{answerList && !answerList[requestStep]?.isResult && <QstTst paramMap={paramMap}/>}
									{answerList && answerList[requestStep]?.isResult && <QstTstResult paramMap={paramMap}/>}
								</>
							}

							{/* 설문 과제 컨텐츠 */}
							{eduMainVO.contentList[requestStep]?.contentType === `poll` && <QstPoll paramMap={paramMap}/>}

							{/* 파일 컨텐츠 */}
							{eduMainVO.contentList[requestStep]?.contentType === `file` && <QstFile paramMap={paramMap}/>}
						</>
					)
				}

			{/*<div className="toast-message active" style={{top: `104px`}}>
				<div className="txt">
					입력하신 내용이 저장되었습니다.
				</div>
				<button type="button" className="btn-close"><span className="blind">닫기</span></button>
			</div>*/}
			
			{
				// :::: 한줄평 (설문조사, 테스트는 제외)
				eduMainVO && (eduMainVO.contentList[requestStep]?.contentType !== `poll` && eduMainVO.contentList[requestStep]?.contentType !== `tst`)
				&& <AudienceReview emIdx={emIdx} bidx={0} type={ReviewType.EDUCATION} />
			}
			
			{teacherVO && (
				<>
					<section className="writer-section">
						<div className="inner">
							{/*<!-- title -->*/}
							<div className="section-title">
								<h3 className="tit">지식 제작자</h3>
							</div>
							<div className="knowledge-writer">

							<img src={FileUtils.getFileUrl(teacherVO?.profileSrc ?? "")}
							     onError={FileUtils.onErrorImg}
							     alt="" className="img-profile"/>
							<div className="text-wrap">
								<strong className="name">{teacherVO?.userName}</strong>
								<span className="job"> {teacherVO?.coInfoVO?.coName} {/*{teacherVO?.coInfoVO?.coName}*/}</span>
								<span className="job">{teacherVO?.teamName}</span>
							</div>
							{
								(!teacherVO?.isAdmin ?? false) && (teacherVO?.coEmailId !== MemberUtils.getLoginId()) && (
									<button type="button"
											className="btn red"
											data-bs-toggle="modal"
											data-bs-target="#qnaModal">에듀톡 문의하기</button>
								)
							}

							</div>
						</div>
					</section>
					<ProEduTalkModal receiver={teacherVO?.userName ?? ""}
									 receiverKey={teacherVO?.coEmailId ?? ""}
									 boardVO={null}/>
				</>
	)
			}
		</main>
	);
	
	
}
export default EduMainDetail














const EduTitleDom: React.FC<{ paramMap: HWMap }> = ({paramMap}) => {
	
	const [mainTitle, setMainTitle] = useState<string>("")
	const [contentTitle, setContentTitle] = useState<string>("")
	const [startDate, setStartDate] = useState<string>("")
	const [endDate, setEndDate] = useState<string>("")
	const [introduce, setIntroduce] = useState<string>("")
	
	/**
	 * 받아온 데이터 정의
	 */
	useEffect(() => {
		if (paramMap?.mainTitle !== undefined && mainTitle === "") {
			WebUtils.log(paramMap)
			setMainTitle(paramMap.mainTitle)
			setStartDate(paramMap.startDate.replace(/-/gi, ".") )
			setEndDate(paramMap.endDate.replace(/-/gi, ".") )
			setIntroduce(paramMap.introduce)
		}
	}, [paramMap]);

	useEffect(() => {
		setContentTitle(paramMap.contentTitle)

	}, [paramMap.contentTitle]);

	return (
		<>
			<div className="detail-title">
	            <span className="cate">
	                <a href="#" onClick={e=> e.preventDefault()}>{mainTitle}</a>
	            </span>
				<h2 className="tit">{contentTitle}</h2>
				<span className="date">
				{startDate && endDate &&
					`${startDate} ~ ${endDate}`
				}
			</span>
			</div>
			{
				introduce &&
				<div className="notice-message">
                    <a href="#" onClick={e=> e.preventDefault()}>
                        {introduce}
                    </a>
				</div>
			}
		</>
	)
}
export {EduTitleDom}

















const EduContentIndex: React.FC<{ paramMap: HWMap }> = ({paramMap}) => {
	
	let {emIdxText, stepText, eduTitle} = useParams();
	const [contentList, setContentList] = useState<EduContentVO[]>([])
	const [answerList, setAnswerList] = useState<EduAnswerVO[]>([])
	
	const [created, setCreated] = useState<boolean>(false)
	const [mounted, setMounted] = useState<boolean>(false)
	
	/**
	 * 받아온 데이터 정의
	 */
	useEffect(() => {
		WebUtils.log(paramMap)
		setAnswerList( paramMap.answerList )
		
		if ( paramMap.contentList ) {
			
			/**
			 * 컨텐츠 타입 표출용 정의
			 */
			const tmpList = paramMap.contentList.map((content:EduContentVO, index:number) => {
				let contentCode = '';
				let contentTxt = '';
				let contentTitle = '';
				let contentInfo = '';
				if ( content.contentType === "mov" || content.contentType === "file" || content.contentType === "card" ) {
					contentCode = "index";
					contentTxt = "학습목록";
					
					content.contentType === "mov" && (
						contentTitle = content.qstMovVO.movTitle,
						contentInfo = `영상 ${dateUtils.formatTime(content.qstMovVO.playSeconds)}`
					)
					content.contentType === "file" && (
						contentTitle = content.qstFileVO.fileTitle,
						contentInfo = `파일`
					)
					content.contentType === "card" && (
						contentTitle = content.qstCardVO.cardName,
						contentInfo = `카드뉴스`
					)
					
				} else if (content.contentType === "tst") {
					contentCode = "tst";
					contentTxt = "테스트";
					contentTitle = content.qstTstOptionVO?.testTitle;
					contentInfo = "";
				} else if (content.contentType === "poll") {
					contentCode = "poll";
					contentTxt = "설문";
					contentTitle = content.qstPollOptionVO?.pollTitle;
					contentInfo = "";
				}
				return { ...content, contentCode, contentTxt, contentTitle, contentInfo };
			})
			
			/**
			 * 전단계 컨텐츠와 같은 타입일 경우 제목 삭제
			 */
			setContentList(
				tmpList.map((content:EduContentVO, index:number) => {
					
					if ( index > 0 && content.contentCode === tmpList[index - 1].contentCode ) {
						content.contentTxt = "";
					}
					
					return { ...content };
				})
			)
			
			setCreated(true)
		}
	}, [paramMap]);
	
	
	useEffect(() => {
		
		if ( created ) {
			CommonJS.floatingNav()
		}
		
	}, [created])
	
	
	return (
		<nav className="floating-nav">
			<div className="nav-title">
				{/*<h3 className="tit">학습 목록</h3>*/}
			</div>
			<div className="nav-content">
					
					{
						contentList.map((content, index) => {
							return (
								<div className="list-wrap" key={index}>
									<strong>{content.contentTxt}</strong>
									<ul className="edu-list">
										<li className={`
											${index.toString() === stepText ? "active" : ""}
											${answerList[index]?.isComplete ? "complete" : ""}
										`} key={index}>
											<a href="#" onClick={e => {
												e.preventDefault()
												paramMap._goStep(index)
											}}>
												<b className="tit">{index+1}. {content.contentTitle}</b>
												<div className="info">
													<span className="data">{content.contentInfo}</span>
													<span className="state">{answerList[index]?.isComplete ? "완료" : "미완료"}</span>
												</div>
											</a>
										</li>
									</ul>
								</div>
							)
						})
					}
				
			</div>
		</nav>
	)
}
export {EduContentIndex}